import "../Css/dumpyard.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Prismic from "@prismicio/client";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";

import Loading from "../Assets/loading.json";
import Contact from "./contact";
import Footer from "./footer";
import Nav from "./Navigation";
import date from "../Assets/ic_calender.svg";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { Link as Link2 } from "react-scroll";
import menu from "../Assets/Menu.svg";



function Dumpyard() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [align, setAlign] = useState("end");
  const [position, setPosition] = useState("end");
  const [viewScroll, setViewScroll] = useState("close");

  const apiEndpoint = "https://harshitsharma.prismic.io/api/v2";
  const accessToken =
    "MC5ZYUVtbXhNQUFETUFuODZN.77-977-9Ye-_ve-_vX8o77-977-977-977-977-9a--_ve-_ve-_ve-_ve-_ve-_vQITBF5h77-977-977-977-977-977-9TO-_vQ";
  // This is where you would add your access token for a Private repository
  const Client = Prismic.client(apiEndpoint, { accessToken });
  const [doc, setDocData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await Client.query(
          Prismic.Predicates.at("document.type", "dumpyard"),
          {
            orderings: "[my.dumpyard.order desc]" 
            // Adjust this field name to match the correct field in your schema
          }
          
        );
        
        if (response) {
          setDocData(response.results[0].data);
          console.error("Error fetching data:", response.results);
          
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
  
    fetchData();
  }, []);
  

  return (
    <>
      <Nav />
      <Menu
          menuButton={<img className="menu" src={menu} />}
          key="top"
          direction="top"
          align={align}
          position={position}
          viewScroll={viewScroll}
          gap={12}
          transition
        >
          <MenuItem>
            <Link to="/" style={{ textDecoration: "none", color: "White" }}>
              Home
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to="/archives" style={{ textDecoration: "none", color: "White" }}>
              Archives
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              to="/about"
              style={{ textDecoration: "none", color: "White" }}
            >
              About Me
            </Link>
          </MenuItem>
          <MenuItem>
            <Link2
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              style={{ textDecoration: "none", color: "White" }}
            >
              Let's Connect
            </Link2>
          </MenuItem>
        </Menu>
      <div className="dumpyard-container">
        <div className="dumpyard">
          <a onClick={() => navigate(-1)}>← Go Back</a>
          <h1 className="dumpyard-text">Archives</h1>

          <p>From 2019 - Present</p>
        </div>

        <div className="responsive-grid">
          {doc?.dumpyard.map((e) => (
            <div className="images">
              {e.content_type == "Image" ? (
                <>
                  {isLoading ? (
                    <Lottie animationData={Loading} loop={true} />
                  ) : (
                    <div style={{ position: "relative" }}>
                      <img className="dump-image" src={e.image_url} />
                      <div className="dump-pills">
                        {e?.tags_check ? (
                          <div className="dump-tag">
                            <p>{e.tag}</p>
                          </div>
                        ) : null}

                        {e?.link_check ? (
                          <a className="dump-link">Link ↗</a>
                        ) : null}
                      </div>

                      <p className="dump-date">
                        <img src={date} />
                        {e.date}
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isLoading ? (
                    <Lottie animationData={Loading} loop={true} />
                  ) : (
                    <div style={{ position: "relative" }}>
                      
                      <video
                        className="dump-video"
                        src={e.video_url}
                        muted
                        playsInline
                        loop="loop"
                        autoPlay="autoplay"
                      ></video>
                      <div className="dump-pills">
                        {e?.tags_check ? (
                          <div className="dump-tag">
                            <p>{e.tag}</p>
                          </div>
                        ) : null}

                        {e?.link_check ? (
                          <a className="dump-link">Link ↗</a>
                        ) : null}
                      </div>

                      <p className="dump-date">
                        <img src={date} />
                        {e.date}
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
       
      </div>
      <section id="contact">
          <Contact />
          <Footer />
        </section>
    </>
  );
}

export default Dumpyard;
